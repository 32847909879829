<template>
  <div>
    <about-bar></about-bar>
    <TopParallax
      title="BELIEFS"
      :img-src="imgSrc"
    />
    <about-adventists></about-adventists>
  </div>
</template>

<script>
import AboutBar from '@/components/AboutBar.vue'
import TopParallax from '@/components/TopParallax.vue'
import AboutAdventists from '@/components/AboutAdventists.vue'

export default {
  components: {
    TopParallax,
    AboutBar,
    AboutAdventists
  },
  data() {
    return {
      imgSrc: require('@/assets/images/beliefs_banner.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'About'
      }
    }
  }
}
</script>
